import React, { useEffect, useState } from 'react';
// import { Nav, NavItem, NavLink, UncontrolledTooltip } from 'reactstrap';
import { Nav } from 'reactstrap';
import ProfileDropdown from './ProfileDropdown';
import NotificationDropdown from './NotificationDropdown';
import { toast } from 'react-toastify';
import getFirebase from '../../services/firebase';
import { getAdminToken } from '../../services/authService';

const firebase = getFirebase();
const db = getFirebase().firestore();

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
};

const TopNavRightSideNavItem = () => {
  const [loading, setLoading] = useState(true);
  const [uid, setUid] = useState(null);
  const [loggedIn, setLoggedIn] = useState(null);
  // const [isAdmin, setIsAdmin] = useState(false);
  const [name, setName] = useState('');
  const [userPhoto, setUserPhoto] = useState('');
  const [level, setLevel] = useState('INCOMPLETE_MEMBER');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const GetToken = async () => {
      const token = await getAdminToken();
    };

    GetToken();
  }, []);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user && user.uid) {
        setUid(user.uid);

        db.collection('authority')
          .doc(user.uid)
          .get()
          .then(doc => {
            if (doc.exists) {
              setLevel(doc.data().level);

              if (doc.data().name) {
                setName(`${doc.data().name ?? ''} ${doc.data().surname ?? ''}`);
              }
            }
          })
          .catch(error => {
            console.error('Error getting document:', error);
            toast.error('An error occured!', {
              autoClose: 100
            });
          });

        db.collection('members')
          .doc(user.uid)
          .get()
          .then(doc => {
            if (doc.exists) {
              if (doc.data().PersonalDetails.photo) {
                setUserPhoto(doc.data().PersonalDetails.photo);
              }
            }
          })
          .catch(error => {
            console.log("Error retrieving member's details:", error);
          });

        setLoggedIn(true);
        setLoading(false);
      } else {
        setLoggedIn(false);
        setLoading(false);
      }
    });
  }, []);

  return (
    !loading &&
    name && (
      <Nav navbar className="navbar-nav-icons ml-auto flex-row align-items-center">
        {windowDimensions.width > 430 && (
          <li className="d-sm-none d-md-none d-lg-block">
            {name}
            <br />
            {level}
          </li>
        )}
        <NotificationDropdown />
        <ProfileDropdown uid={uid} level={level} loggedIn={loggedIn} name={name} userPhoto={userPhoto} />
      </Nav>
    )
  );
};

export default TopNavRightSideNavItem;
