import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import 'dotenv/config';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import '../src/assets/css/MaintenanceModeMessage.css';

const App = () => {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
    // <div className="maintenance-message">
    //   <h1>Maintenance Mode</h1>
    //   <h3>...Sorry we are currently updating the site.</h3><p>Please check again in a few minutes.</p>
    // </div>
  );
};

export default App;
