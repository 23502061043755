import React, { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import is from 'is_js';
import PropTypes from 'prop-types';
import { Collapse, Nav, Navbar } from 'reactstrap';
// import bgNavbarImg from '../../assets/img/generic/bg-navbar.png';
import { navbarBreakPoint } from '../../config';
import AppContext from '../../context/Context';
import routes from '../../routes';
import routesIncompleteMember from '../../routesIncompleteMember';
import routesAdmin from '../../routesAdmin';
import Flex from '../common/Flex';
import Logo from './Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import Loader from '../common/Loader';

import getFirebase from '../../services/firebase';
const firebase = getFirebase();
const db = getFirebase().firestore();

const NavbarVertical = ({ navbarStyle }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const [level, setLevel] = useState('');
  const [loading, setLoading] = useState(true);

  const navBarRef = useRef(null);

  const { showBurgerMenu, isNavbarVerticalCollapsed, setIsNavbarVerticalCollapsed } = useContext(AppContext);

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add('navbar-vertical-collapsed');
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user && user.uid) {
        db.collection('authority')
          .doc(user.uid)
          .get()
          .then(doc => {
            if (doc.exists && doc.data().level) {
              setLevel(doc.data().level);
            } else if (!doc.exists) {
              setLevel('INCOMPLETE_MEMBER');
              db.collection('authority')
                .doc(doc.id)
                .set({ level: 'INCOMPLETE_MEMBER' })
                .catch(error => {
                  console.error('Error setting level', error);
                });
            }

            if (doc.exists && doc.data().level === 'ADMIN') {
              setIsAdmin(true);
            } else {
              setIsAdmin(false);
            }
          })
          .then(() => {
            setLoading(false);
          })
          .catch(error => {
            console.error('Error getting document:', error);
          });
      } else {
        setIsAdmin(false);
      }
    });
  }, []);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical navbar-glass', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      light
    >
      <Flex align="center">
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
        <Logo at="navbar-vertical" width={'100%'} />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className="scrollbar"
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove('navbar-vertical-collapsed-hover');
        }}
        style={
          navbarStyle === 'card'
          // && {
          //   backgroundImage: `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbarImg})`
          // }
        }
      >
        {loading ? (
          <Loader />
        ) : (
          <Nav navbar vertical>
            <NavbarVerticalMenu
              routes={
                isAdmin
                  ? routesAdmin
                  : level === 'INCOMPLETE_MEMBER' || level === 'PENDING_VERIFICATION_MEMBER'
                  ? routesIncompleteMember
                  : routes
              }
            />
          </Nav>
        )}
        {/* <div className="settings px-3 px-xl-0">
          {isCombo && (
            <div className={`d-${topNavbarBreakpoint}-none`}>
              <div className="navbar-vertical-divider">
                <hr className="navbar-vertical-hr my-2" />
              </div>
              <Nav navbar>
                <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} setShowBurgerMenu={setShowBurgerMenu} />
              </Nav>
            </div>
          )}
          <div className="navbar-vertical-divider">
            <hr className="navbar-vertical-hr my-2" />
          </div>
        </div> */}
      </Collapse>
    </Navbar>
  );
};

NavbarVertical.protoTypes = {
  navbarStyle: PropTypes.string
};

NavbarVertical.defaultProps = {
  navbarStyle: 'transparent'
};

export default NavbarVertical;
