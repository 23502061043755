import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Badge } from 'reactstrap';

const badgeFormatter = status => {
  let color = '';
  let icon = '';
  let text = '';
  switch (status?.toUpperCase()) {
    case 'PENDING_VERIFICATION':
      color = 'secondary';
      icon = 'stream';
      text = 'Pending Verification';
      break;
    case 'PENDING_APPROVAL':
      color = 'secondary';
      icon = 'stream';
      text = 'Pending Approval';
      break;
    case 'NOT_APPROVED':
      color = 'warning';
      icon = 'stream';
      text = 'Not Approved';
      break;
    case 'APPROVED':
      color = 'success';
      icon = 'check';
      text = 'Approved';
      break;
    default:
      color = 'warning';
      icon = 'stream';
      text = 'Pending - Blocked';
  }
  return (
    <Badge color={`soft-${color}`} className="rounded-capsule">
      {text}
      <FontAwesomeIcon icon={icon} transform="shrink-2" className="ml-1" />
    </Badge>
  );
};

const memberFormatter = memberName => (
  <Link to={'#'} className="font-weight-semi-bold">
    {memberName}
  </Link>
);

const dateFormatter = date => {
  return date ? <div className="font-weight-semi-bold">{date.substring(0, 10)}</div> : '';
};

const amountFormatter = amount => <>${amount}</>;

export const servicesDashboardColumns = [
  {
    dataField: 'name',
    text: 'Member Name',
    formatter: memberFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true
  },
  {
    dataField: 'type',
    text: 'Type',
    classes: 'border-0 align-left',
    headerClasses: 'border-0',
    sort: true
  },
  {
    dataField: 'status',
    text: 'Application Status',
    formatter: badgeFormatter,
    classes: 'border-0 align-middle fs-0',
    headerClasses: 'border-0',
    sort: true
  },

  {
    dataField: 'fee',
    text: 'Fee Payable',
    formatter: amountFormatter,
    classes: 'border-0 align-right',
    headerClasses: 'border-0',
    sort: true
  },
  {
    dataField: 'paymentStatus',
    text: 'Payment Status',
    classes: 'border-0 align-right',
    headerClasses: 'border-0',
    sort: true
  },
  {
    dataField: 'applicationDate',
    text: 'Date',
    formatter: dateFormatter,
    classes: 'border-0 align-right',
    headerClasses: 'border-0',
    sort: true
  }
];

export const renewalsDashboardColumns = [
  {
    dataField: 'name',
    text: 'Member Name',
    formatter: memberFormatter,
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true
  },
  {
    dataField: 'transactionId',
    text: 'Transaction #',
    classes: 'border-0 align-middle',
    headerClasses: 'border-0',
    sort: true
  },
  {
    dataField: 'type',
    text: 'Type',
    classes: 'border-0 align-left',
    headerClasses: 'border-0',
    sort: true
  },
  {
    dataField: 'status',
    text: 'Renewal Status',
    formatter: badgeFormatter,
    classes: 'border-0 align-middle fs-0',
    headerClasses: 'border-0',
    sort: true
  },
  {
    dataField: 'applicationDate',
    text: 'Date',
    formatter: dateFormatter,
    classes: 'border-0 align-right',
    headerClasses: 'border-0',
    sort: true
  }
];

export default { renewalsDashboardColumns, memberDashboardColumns: servicesDashboardColumns };
