import React, { Component } from 'react';
import { UserContext } from '../../context/Context';
import rawEmails from '../../data/email/emails';
import { isIterableArray } from '../../helpers/utils';

class UserProvider extends Component {
  setEmails = emails => this.setState({ emails });
  setCurrentFilter = currentFilter => this.setState({ currentFilter });
  setApplicationFormType = applicationFormType => this.setState({ applicationFormType });

  // Handlers
  handleAction = (action, selectedIds) => {
    const { emails, setEmails } = this.state;

    setEmails(
      action === 'delete' || action === 'archive'
        ? emails.filter(({ id }) => !selectedIds.includes(id))
        : emails.map(email => (selectedIds.includes(email.id) ? { ...email, [action]: !email[action] } : email))
    );
  };

  handleFilter = filter => {
    const { setEmails, setCurrentFilter } = this.state;
    setCurrentFilter(filter);

    switch (filter) {
      case 'all':
        return setEmails(rawEmails);
      case 'unread':
        return setEmails(rawEmails.filter(email => !email.read));
      case 'attachments':
        return setEmails(rawEmails.filter(email => isIterableArray(email.attachments)));
      default:
        return setEmails(rawEmails.filter(email => email[filter]));
    }
  };

  handleApplicationFormType = name => {
    console.log('name', name);
    const { setApplicationFormType } = this.state;
    setApplicationFormType(name);

    this.setState({ applicationFormType: name });
  };

  state = {
    applicationFormType: 'mini--',
    setApplicationFormType: this.setApplicationFormType,
    setCurrentFilter: this.setCurrentFilter,
    emails: [],
    setEmails: this.setEmails,
    filters: ['all', 'unread', 'star', 'attachments', 'archive', 'snooze'],
    currentFilter: 'all',
    handleFilter: this.handleFilter,
    handleAction: this.handleAction,
    handleApplicationFormType: this.handleApplicationFormType
  };

  render() {
    return <UserContext.Provider value={this.state}>{this.props.children}</UserContext.Provider>;
  }
}

export default UserProvider;
