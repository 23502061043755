import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Card, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import ListGroup from 'reactstrap/es/ListGroup';
import ListGroupItem from 'reactstrap/es/ListGroupItem';
// import { rawEarlierNotifications, rawNewNotifications } from '../../data/notification/notification';
import { isIterableArray } from '../../helpers/utils';
// import useFakeFetch from '../../hooks/useFakeFetch';
import FalconCardHeader from '../common/FalconCardHeader';
import Notification from '../notification/Notification';
import getFirebase from '../../services/firebase';
import { toast } from 'react-toastify';

const db = getFirebase().firestore();

const NotificationDropdown = () => {
  // State
  // const { data: newNotifications, setData: setNewNotifications } = useFakeFetch(rawNewNotifications);
  // const { data: earlierNotifications, setData: setEarlierNotifications } = useFakeFetch(rawEarlierNotifications);
  const [isOpen, setIsOpen] = useState(false);
  // const [isAllRead, setIsAllRead] = useState(false);
  const [isAllRead] = useState(false);
  const [memberUid, setMemberUid] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [comments, setComments] = useState([]);

  useEffect(() => {
    getFirebase()
      .auth()
      .onAuthStateChanged(user => {
        if (user && user.email && user.uid) {
          setMemberUid(user.uid);
          setLoggedIn(true);
        } else {
          setLoggedIn(false);
        }
      });
  }, []);

  useEffect(() => {
    if (memberUid && loggedIn) {
      let commentsBucket = [];

      db.collection('correspondence')
        .doc('2023')
        .collection('comments')
        .doc(memberUid)
        .collection('sent')
        .where('commentType', '==', 'member')
        .orderBy('epoch', 'desc')
        .limit(3)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            // console.log(doc.data().text);
            commentsBucket.push({
              id: doc.id,
              date: doc.data().date,
              text: doc.data().text,
              unread: doc.data().unread,
              className: 'rounded-0 border-x-0 border-300 border-bottom-0'
            });
          });
        })
        .then(() => {
          db.collection('correspondence')
            .doc('2024')
            .collection('comments')
            .doc(memberUid)
            .collection('sent')
            .where('commentType', '==', 'member')
            .orderBy('epoch', 'desc')
            .limit(3)
            .get()
            .then(querySnapshot => {
              querySnapshot.forEach(doc => {
                commentsBucket.push({
                  id: doc.id,
                  date: doc.data().date,
                  text: doc.data().text,
                  unread: doc.data().unread,
                  className: 'rounded-0 border-x-0 border-300 border-bottom-0'
                });
              });
            })
            .then(() => {
              // console.log(membersBucket);
              // return { data: membersBucket };
              setComments(commentsBucket);
              // setLoading(false);
            });
        })
        .catch(error => {
          // setLoading(false);
          console.error('Error getting document:', error);
          toast.error('An error occured while retrieving members', { autoClose: 10000 });
        });
    }
  }, [loggedIn, memberUid]);

  // Handler
  const handleToggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  // const markAsRead = e => {
  //   e.preventDefault();

  //   const updatedNewNotifications = comments.map(notification => {
  //     if (notification.hasOwnProperty('unread')) {
  //       return {
  //         ...notification,
  //         unread: false
  //       };
  //     }
  //     return notification;
  //   });

  //   const updatedEarlierNotifications = earlierNotifications.map(notification => {
  //     if (notification.hasOwnProperty('unread')) {
  //       return {
  //         ...notification,
  //         unread: false
  //       };
  //     }
  //     setIsAllRead(true);
  //     return notification;
  //   });

  //   setNewNotifications(updatedNewNotifications);
  //   setEarlierNotifications(updatedEarlierNotifications);
  // };

  return comments.length > 0 ? (
    <Dropdown
      nav
      inNavbar
      isOpen={isOpen}
      toggle={handleToggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setIsOpen(false);
      }}
    >
      <DropdownToggle
        nav
        className={classNames('px-0', {
          'notification-indicator notification-indicator-primary': !isAllRead
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <Card className="card-notification shadow-none" style={{ maxWidth: '20rem' }}>
          <FalconCardHeader className="card-header" title="Notifications" titleTag="h6" light={false}>
            {/* <Link className="card-link font-weight-normal" to="#!" onClick={markAsRead}>
              Mark all as read
            </Link> */}
          </FalconCardHeader>
          <ListGroup flush className="font-weight-normal fs--1">
            {/* <div className="list-group-title">NEW</div> */}
            {isIterableArray(comments) &&
              comments.map((comment, index) => (
                <ListGroupItem key={index} onClick={handleToggle}>
                  <Notification {...comment} flush source="DropdownMenu" />
                </ListGroupItem>
              ))}
            {/* <div className="list-group-title">EARLIER</div>
            {isIterableArray(earlierNotifications) &&
              earlierNotifications.map((notification, index) => (
                <ListGroupItem key={index} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroupItem>
              ))} */}
          </ListGroup>
          <div className="card-footer text-center border-top" onClick={handleToggle}>
            <Link className="card-link d-block" to="/pages/notifications">
              View all
            </Link>
          </div>
        </Card>
      </DropdownMenu>
    </Dropdown>
  ) : null;
};

export default NotificationDropdown;
