import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import loadable from '@loadable/component';
import { Card, CardBody, Input } from 'reactstrap';
// import CountUp from 'react-countup';
import { toast } from 'react-toastify';
import CardSummary from './CardSummary';
// import ActiveUsersBarChart from './ActiveUsersBarChart';
// import PaymentsLineChart from './PaymentsLineChart';
import FalconCardHeader from '../common/FalconCardHeader';
// import ButtonIcon from '../common/ButtonIcon';
import getFirebase from '../../services/firebase';
import Loader from '../common/Loader';

import DashBoardDebtStatus from './DashBoardDebtStatus';
import { serviceRequestsTypes } from '../../helpers/utils';
import { renewalsDashboardColumns, servicesDashboardColumns } from '../../table-columns/memeberDashboard';

const firebase = getFirebase();
const db = getFirebase().firestore();
const PurchasesTable = loadable(() => import('./PurchasesTable'));
const ServiceRequestsTable = loadable(() => import('./ServiceRequestsTable'));

// const ActiveUsersMap = loadable(() => import('./ActiveUsersMap'));

const Dashboard = () => {
  const [loggedIn, setLoggedIn] = useState(null);
  const [uid, setUid] = useState(null);
  const [name, setName] = useState('');
  const [role, setRole] = useState([]);
  const [level, setLevel] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [memberDetails, setMemberDetails] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [certificateOfGoodStandingId, setCertificateOfGoodStandingId] = useState();
  const [membershipNumber, setMembershipNumber] = useState('-');
  const [regPrefix, setRegPrefix] = useState('');
  const [USDZWL, setUSDZWL] = useState(0);
  const [registersCount, setRegistersCount] = useState(' ');
  const [approvedMembersCount, setApprovedMembersCount] = useState(' ');
  const [searchTermMembers, setSearchTermMembers] = useState('');
  const [searchTermServices, setSearchTermServices] = useState('');

  let history = useHistory();

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      if (user && user.uid) {
        setLoggedIn(true);
        setUid(user.uid);
      } else {
        setLoggedIn(false);
        history.push(`/authentication/basic/login`);
      }
    });
  }, [history, isSelected, role]);

  useEffect(() => {
    if (uid) {
      db.collection('authority')
        .doc(uid)
        .get()
        .then(doc => {
          if (doc.exists) {
            doc.data().level && setLevel(doc.data().level);
            setName(`${doc.data().name ?? ''} ${doc.data().surname ?? ''}`);
            doc.data().role && setRole(doc.data().role);
            if (doc.data().level === 'ADMIN') {
              setIsAdmin(true);
            }
            if (doc.data().level === 'APPROVED_MEMBER') {
              setMembershipNumber(doc.data().membershipNumber);
              setRegPrefix(doc.data().regPrefix);
            }
            if (doc.data().role && doc.data().role.includes('SUPERADMIN')) {
              setIsSuperAdmin(true);
            }
          }
        })
        .catch(error => {
          console.error('Error getting member details:', error);
          toast.error('An error occured!', {
            autoClose: 10000
          });
        });
    }
  }, [uid]);

  useEffect(() => {
    if (uid) {
      db.collection('members')
        .doc(uid)
        .get()
        .then(doc => {
          setMemberDetails(doc.data());
        })
        .catch(error => {
          console.log("Error retrieving member's details:", error);
          toast.error("An error occured while retrieving member's details", { autoClose: 500 });
        });
    }
  }, [uid]);

  useEffect(() => {
    if (isAdmin)
      db.collection('authority')
        .where('level', '==', 'APPROVED_MEMBER')
        .get()
        .then(querySnapshot => {
          setApprovedMembersCount(querySnapshot.size);
        })
        .catch(error => {
          console.error('Error counting members:', error);
          toast.error('An error occured while counting members!', {
            autoClose: 1000
          });
        });
  }, [isAdmin]);

  useEffect(() => {
    if (isAdmin)
      db.collection('registers')
        .where('active', '==', true)
        .get()
        .then(querySnapshot => {
          setRegistersCount(querySnapshot.size);
        })
        .catch(error => {
          console.error('Error getting document:', error);
          toast.error('An error occured!', {
            autoClose: 1000
          });
        });
  }, [isAdmin]);

  useEffect(() => {
    if (isAdmin)
      db.collection('rates')
        .where('todayEpoch', '>', 1)
        .orderBy('todayEpoch', 'desc')
        .limit(1)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            setUSDZWL(Number(doc.data().USDZWL).toFixed(2));
          });
        })
        .catch(error => {
          console.error('Error getting document:', error);
          toast.error('An error occured!', {
            autoClose: 10000
          });
        });
  }, [isAdmin]);

  useEffect(() => {
    if (membershipNumber && uid) {
      db.collection('certificates')
        .where('memberId', '==', uid)
        .where('type', '==', 'Certificate of Good Standing')
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            setCertificateOfGoodStandingId(doc.id);
          });
        })
        .catch(error => {
          console.log("Error retrieving member's certificates", error);
        });
    }
  }, [membershipNumber, uid]);

  return loggedIn && level ? (
    <>
      <DashBoardDebtStatus uid={uid} />

      <div className="card-deck">
        {isAdmin && (
          <>
            <CardSummary
              rate=""
              title="Total Registers"
              color="info"
              linkText="View Registers"
              to="/pages/pricing/registers"
            >
              {registersCount}
            </CardSummary>

            <CardSummary
              rate=""
              title="Total Approved Members"
              color="info"
              linkText="View Approved Members"
              to="/pages/users/roles/APPROVED_MEMBER"
            >
              {approvedMembersCount}
            </CardSummary>

            {isSuperAdmin ? (
              <CardSummary
                title="Rate: 1 USD to ZWL"
                color="info"
                linkText="Edit Rate"
                prefix="ZWL"
                to="/pages/rates-edit"
              >
                ZWL {USDZWL}
              </CardSummary>
            ) : (
              <CardSummary title="Rate: 1 USD to ZWL" color="info" prefix="ZWL">
                ZWL {USDZWL}
              </CardSummary>
            )}
          </>
        )}

        {memberDetails && level.includes('MEMBER') && memberDetails.ContactDetails && (
          <CardSummary rate="" title="My Details" color="info" type="info" linkText="See all">
            Name: <strong>{name}</strong>
            <br />
            Email: <strong>{memberDetails.ContactDetails && memberDetails.ContactDetails.email}</strong>
            <br />
            Membership Number: <strong>{`${regPrefix}${membershipNumber?.toString().replace(/\D/g, '')}`}</strong>
            {/* Membership Number: <strong>{`${membershipNumber}`}</strong> */}
            <br />
            {regPrefix && (
              <span>
                Register Prefix: <strong>{`${regPrefix}`}</strong>
              </span>
            )}
            <br />
          </CardSummary>
        )}

        {level === 'APPROVED_MEMBER' && (
          <CardSummary
            rate=""
            title="Registration Pack"
            color="info"
            type="info"
            linkText="View Pack"
            to={'/pages/registration-pack'}
          />
        )}
      </div>

      <div className="card-deck">
        {certificateOfGoodStandingId && (
          <CardSummary
            rate=""
            title="Certificate of Good Standing"
            color="info"
            type="info"
            linkText="View PDF"
            to={`/pages/download/${certificateOfGoodStandingId}`}
          />
        )}

        {level === 'APPROVED_MEMBER' && (
          <CardSummary
            rate=""
            title="My Applications"
            color="info"
            type="info"
            linkText="View List"
            to={`/pages/my-applications`}
          />
        )}

        {level === 'INCOMPLETE_MEMBER' && (
          <CardSummary
            rate=""
            title="Welcome to MRPCZ Members Portal"
            color="info"
            type="info"
            linkText="Start Application"
            to={'/pages/member-type-check'}
          />
        )}
      </div>

      {isAdmin && (
        <Card className="mb-3">
          <FalconCardHeader title="Membership Service" light={false}>
            <Input
              placeholder="Search by name"
              onChange={e => setSearchTermMembers(e.target.value)}
              type="text"
              bsSize="sm"
            />
          </FalconCardHeader>
          <CardBody className="p-0">
            <PurchasesTable setIsSelected={setIsSelected} role={role} searchTerm={searchTermMembers} />
          </CardBody>
        </Card>
      )}

      {isAdmin && (
        <Card className="mb-3">
          <FalconCardHeader title="Renewals" light={false}>
            <Input
              placeholder="Search by name"
              onChange={e => setSearchTermServices(e.target.value)}
              type="text"
              bsSize="sm"
            />
          </FalconCardHeader>
          <CardBody className="p-0">
            <ServiceRequestsTable
              setIsSelected={setIsSelected}
              role={role}
              searchTerm={searchTermServices}
              serviceExclusions={[
                serviceRequestsTypes.membership,
                serviceRequestsTypes.additionalQualification,
                serviceRequestsTypes.certificateGoodStanding,
                serviceRequestsTypes.disciplinary,
                serviceRequestsTypes.duplicateCertificate,
                serviceRequestsTypes.intern2permanent,
                serviceRequestsTypes.nameAlteration,
                serviceRequestsTypes.registerInstitution
              ]}
              columns={renewalsDashboardColumns}
            />
          </CardBody>
        </Card>
      )}

      {isAdmin && (
        <Card className="mb-3">
          <FalconCardHeader title="Service Requests" light={false}>
            <Input
              placeholder="Search by name"
              onChange={e => setSearchTermServices(e.target.value)}
              type="text"
              bsSize="sm"
            />
          </FalconCardHeader>
          <CardBody className="p-0">
            <ServiceRequestsTable
              setIsSelected={setIsSelected}
              role={role}
              searchTerm={searchTermServices}
              serviceExclusions={[serviceRequestsTypes.membership, serviceRequestsTypes.renewal]}
              columns={servicesDashboardColumns}
            />
          </CardBody>
        </Card>
      )}
    </>
  ) : (
    <Loader />
  );
};

export default Dashboard;
