// import { version } from './config';

export const homeRoutes = {
  name: 'Home',
  to: '/',
  exact: true,
  icon: 'chart-pie',
  children: [
    {
      to: '/',
      name: 'Dashboard',
      exact: true
    },
    { to: '/pages/billing-history', name: 'Billing History' },
    { to: '/pages/my-applications', name: 'My Applications' },
    { to: '/pages/registration-pack', name: 'Registration Pack' }
  ]
};

export const authenticationRoutes = {
  name: 'Authentication',
  to: '/authentication',
  icon: 'lock',
  children: [
    {
      to: '/authentication/basic',
      name: 'Basic',
      children: [
        { to: '/authentication/basic/login', name: 'Login' },
        { to: '/authentication/basic/login-otp', name: 'Login OTP' },
        { to: '/authentication/basic/logout', name: 'Logout' }
      ]
    }
  ]
};


export const pageRoutes = {
  name: 'Forms',
  to: '/pages',
  icon: 'copy',
  children: [
    { to: '/pages/member-type-check', name: 'Application/Renewal Form' },
    { to: '/pages/form-additional-qualification', name: 'Additional Qualification' },
    { to: '/pages/form-duplicate-certificate', name: 'Duplicate Certificate' },
    { to: '/pages/form-alteration-name', name: 'Name Alteration' },
    { to: '/pages/form-registration-institution', name: 'Registration of Institution' },
    { to: '/pages/form-good-standing', name: 'Cert. of Good Standing' },
    { to: '/pages/form-intern2permanent', name: ' Intern to Permanent' }
  ]
};


export default [
  homeRoutes,
  pageRoutes
];
