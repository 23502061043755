export const homeRoutes = {
  name: 'Home',
  to: '/',
  exact: true,
  icon: 'chart-pie',
  children: [
    {
      to: '/',
      name: 'Dashboard',
      exact: true
    }
  ]
};

export const authenticationRoutes = {
  name: 'Authentication',
  to: '/authentication',
  icon: 'lock',
  children: [
    {
      to: '/authentication/basic',
      name: 'Basic',
      children: [
        { to: '/authentication/basic/login', name: 'Login' },
        { to: '/authentication/basic/login-otp', name: 'Login OTP' },
        { to: '/authentication/basic/logout', name: 'Logout' }
      ]
    }
  ]
};

export const pageRoutes = {
  name: 'Forms',
  to: '/pages',
  icon: 'copy',
  children: [{ to: '/pages/member-type-check', name: 'Application Form' }]
};

export default [homeRoutes, pageRoutes];
