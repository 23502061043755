export const homeRoutes = {
  name: 'Home',
  to: '/',
  exact: true,
  icon: 'plug',
  children: [
    {
      to: '/',
      name: 'Dashboard',
      exact: true
    },
    { to: '/pages/users/roles', name: 'User List' },
    { to: '/pages/pricing/service-price-list', name: 'Service Price List' },
    { to: '/pages/pricing/pops-list', name: 'Proof of Payment' },
    { to: '/pages/pricing/registers', name: 'Registers List' },
    { to: '/pages/disciplinary-process', name: 'Disciplinary Process' },
    { to: '/pages/reports', name: 'Reports' },
    { to: '/pages/certificates', name: 'Certificates' }
  ]
};

export default [homeRoutes];
