import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createMarkup from '../../helpers/createMarkup';
// import Avatar from '../common/Avatar';

const Notification = ({
  date,
  id,
  text,
  unread,
  flush,
  className,
  sectionName,
  serviceRequestType,
  applicationId,
  source
}) => (
  <Link
    className={classNames('notification', { 'bg-200': unread, 'notification-flush': flush }, className)}
    to={
      source === 'DropdownMenu'
        ? '/pages/notifications'
        : serviceRequestType
        ? `/pages/service-request-details/${applicationId}`
        : '/pages/apply'
    }
  >
    {/* {avatar && (
      <div className="notification-avatar">
        <Avatar {...avatar} className="mr-3" />
      </div>
    )} */}
    <div className="notification-body">
      {/* <p className={emoji ? 'mb-1' : 'mb-0'} dangerouslySetInnerHTML={createMarkup(children)} /> */}
      {(sectionName || serviceRequestType) && (
        <p className="mb-1">
          <strong>{sectionName ? sectionName : serviceRequestType}:</strong>
        </p>
      )}
      <p className={'mb-1'} dangerouslySetInnerHTML={createMarkup(text)} />
      <span className="notification-time">
        {/* {emoji && (
          <span className="mr-1" role="img" aria-label="Emoji">
            {emoji}
          </span>
        )} */}
        {date}
      </span>
    </div>
  </Link>
);

// const Notification = ({ to, avatar, time, className, unread, flush, emoji, children }) => (
//   <Link className={classNames('notification', { 'bg-200': unread, 'notification-flush': flush }, className)} to={to}>
//     {avatar && (
//       <div className="notification-avatar">
//         <Avatar {...avatar} className="mr-3" />
//       </div>
//     )}
//     <div className="notification-body">
//       <p className={emoji ? 'mb-1' : 'mb-0'} dangerouslySetInnerHTML={createMarkup(children)} />
//       <span className="notification-time">
//         {emoji && (
//           <span className="mr-1" role="img" aria-label="Emoji">
//             {emoji}
//           </span>
//         )}
//         {time}
//       </span>
//     </div>
//   </Link>
// );

Notification.propTypes = {
  text: PropTypes.string,
  date: PropTypes.string,
  id: PropTypes.string,
  to: PropTypes.string,
  // avatar: PropTypes.shape(Avatar.propTypes),
  time: PropTypes.string,
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
  children: PropTypes.node,
  serviceRequestType: PropTypes.string,
  applicationId: PropTypes.string,
  sectionName: PropTypes.string,
  source: PropTypes.string
};

Notification.defaultProps = {
  unread: false,
  flush: false,
  sectionName: '',
  serviceRequestType: '',
  applicationId: '',
  source: ''
};

export default Notification;
