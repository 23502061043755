// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
// import team3 from '../../assets/img/team/3.jpg';
// import blankAvatar from '../../assets/img/team/avatar.png';
import Avatar from '../common/Avatar';

const ProfileDropdown = props => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        {props.userPhoto ? <Avatar src={props.userPhoto} /> : <Avatar name={props.name} />}
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          {/* <DropdownItem className="font-weight-bold text-warning" href="#!">
            <FontAwesomeIcon icon="crown" className="mr-1" />
            <span>Join</span>
          </DropdownItem> */}
          {/* <DropdownItem href="#!">Set status</DropdownItem> */}
          {/* <DropdownItem href="#!">Feedback</DropdownItem> */}
          {/* <DropdownItem divider /> */}
          {/* <DropdownItem tag={Link} to="/pages/settings">
            Settings
          </DropdownItem> */}
          {!props.loggedIn ? (
            <>
              <DropdownItem tag={Link} to="/authentication/basic/login">
                Login
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem tag={Link} to="/authentication/basic/register">
                Register
              </DropdownItem>
            </>
          ) : (
            <>
              {props.level.includes('MEMBER') && (
                <>
                  <DropdownItem tag={Link} to={`/pages/member-details/${props.uid}`}>
                    My Profile
                  </DropdownItem>

                  <DropdownItem divider />
                </>
              )}
              <DropdownItem tag={Link} to="/authentication/basic/logout">
                Logout
              </DropdownItem>
            </>
          )}
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

ProfileDropdown.propTypes = {
  uid: PropTypes.string,
  loggedIn: PropTypes.bool,
  level: PropTypes.string,
  userPhoto: PropTypes.string,
  name: PropTypes.string
};

export default ProfileDropdown;
