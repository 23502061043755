import getFirebase from './firebase';
const firebase = getFirebase();
//const db = firebase.firestore();

export const getAdminToken = async () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged(user => {
      if (user && user.uid) {
        firebase
          .auth()
          .currentUser.getIdToken()
          .then(token => {
            resolve(token);
          })
          .catch(error => {
            reject(error);
          });
      } else {
      }
    });
  });
};
