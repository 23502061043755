import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router';
import getFirebase from '../../services/firebase';

const db = getFirebase().firestore();

export const checkDebts = uid => {
  if (uid) {
    db.collection('invoices')
      .where('uid', '==', uid)
      .where('paymentStatus', '==', 'NOT_PAID')
      .limit(1)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size) {
          return true;
        } else {
          return false;
        }
      })
      .catch(error => {
        console.log("Error retrieving member's billing history", error);
      });
  }
};

export const checkInvoices = uid => {
  if (uid) {
    db.collection('invoices')
      .where('uid', '==', uid)
      .get()
      .then(querySnapshot => {
        if (querySnapshot.size) {
          return true;
        } else {
          return false;
        }
      })
      .catch(error => {
        console.log("Error retrieving member's billing history", error);
      });
  }
};

const DashBoardDebtStatus = ({ uid }) => {
  let history = useHistory();
  const [hasDebts, setHasDebts] = useState(false);

  useEffect(() => {
    if (uid) {
      setHasDebts(checkDebts(uid));
      checkInvoices(uid);
    }
  }, [uid]);

  const viewInvoices = () => {
    history.push('/pages/billing-history');
  };

  return hasDebts ? (
    <Card className="bg-light mb-3">
      <CardBody className="p-3">
        <p className="fs--1 mb-0">
          <Link to="/pages/billing-history">
            <FontAwesomeIcon icon="exchange-alt" transform="rotate-90" className="mr-2" />
            Membership payment outstanding
          </Link>
          . Pay early to avoid penalties.
          <Button
            color="primary"
            size="sm"
            className="mr-2"
            type="button"
            id="Btn_Invoices"
            onClick={viewInvoices}
            style={{ marginLeft: '30px' }}
          >
            View details
          </Button>
        </p>
      </CardBody>
    </Card>
  ) : null;
};

DashBoardDebtStatus.propTypes = {
  uid: PropTypes.string.isRequired
};

export default DashBoardDebtStatus;
